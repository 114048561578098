export default {
  title: `<span class="text">第2回EBC100万ドル取引大会</span>`,
  title2: ``,
  巴萨传奇再启: "新たなチャレンジが始まる",
  成就交易之王: "",
  大赛时间:
    "大会期間：2025年3月1日（土）08:00:00～2025年5月31日（土）05:59:59（日本時間）",
  距开赛: ``,
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  一键跟单: "コピートレード",
  免费复制冠军收益: "参加者の取引シグナルをコピー可能",
  公开透明: "公正・透明",
  观摩冠军交易技巧: "参加者の取引履歴を閲覧可能",
  推荐有礼: "紹介キャンペーン",
  推荐有奖上不封顶: "大会参加者を紹介して報酬を無制限に獲得",
  立即参赛: "口座開設",
  现金奖池: "現金賞金総額",
  巴萨梦之队: "100万ドルチャレンジコース",
  拉玛西亚组: "3万ドルチャレンジコース",
  冠军: "1位",
  亚军: "2位",
  季军: "3位",
  第四至五名: "4～5位",
  第六至十名: "6～10位",
  "$1,000,000交易账号或$200,000现金":
    "20万ドルの賞金または100万ドル分の取引口座 ",
  "1,000,000交易账号或200,000现金":
    "20万ドルの賞金または100万ドル分の取引口座 ",
  大赛推荐官: "取引大会紹介キャンペーン",
  推荐奖励: "大会参加者を紹介して報酬を獲得、上限なし",
  巴萨梦之队HTML: `100万ドルチャレンジコース`,
  拉玛西亚组HTML: `3万ドルチャレンジコース`,
  "/人": "/名",
  活动时间:
    "紹介期間：2025年2月22日（土）08:00:00～2025年5月24日（土）05:59:59（日本時間）",
  推荐流程: "紹介の流れ",
  推荐好友: "参加者を紹介",
  好友参赛: "参加者が取引大会に登録",
  赢得奖金: "報酬を獲得",
  实时赛况: "最新ランキング",
  排名: "ランキング",
  昵称: "ニックネーム",
  服务器: "サーバー",
  账号: "取引口座番号",
  收益额: "利益額",
  收益率: "利益率",
  奖金: "賞金",
  跟单: "フォロー",
  该页面仅展示收益表现排名前10的交易账号:
    `*このページでは、実績トップ10の取引口座のみを表示します。米ドルで表示され、1時間毎に自動更新されます。<br/>
*ご自身のランキング順位はマイページにログインしたあとに、ご確認いただけます。`,
  赛事进程: "大会スケジュール",
  比赛开始: "大会及び紹介開始",
  推荐结束: "紹介期間終了",
  比赛结束: "大会終了",
  颁奖典礼: "授賞式",
  赛组规则: "大会ルール",
  类别: "コース",
  账号要求: "参加資格",
  "新交易账号，且账号余额大于$500": "口座残高が500ドル以上の新規取引口座",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "口座残高が1万ドル以上20万ドル以下の新規取引口座",
  排名规则: "順位決定方法",
  以收益率排名为评判标准: "利益率でランキング付けられます。",
  "收益率=期末净值/账号累积入金":
    "利益率（%）=（最終残高額 / 累積入金額）x 100",
  以净盈利额排名为评判标准: "純利益額でランキング付けられます。",
  持仓限制: "ポジションの制限",
  每个参赛账号最多持有100手仓位:
    "各アカウントの最大ポジションは100 lotsとなります。",
  无持仓限制: "ポジション無制限",
  参赛条款: "",
  参赛须知: "大会概要",
  section6List: [
    {
      t: "1.開催期間  ",
      c: [
        "大会期間：2025年3月1日（土）08:00:00～2025年5月31日（土）05:59:59（日本時間）  ",
      ],
    },
    {
      t: "2.参加資格  ",
      c: [
        "大会参加に使用する取引口座（大会用口座）はEBCのコピートレードコミュニティーでシグナル提供者として登録する必要があります。ただし、大会用口座のシグナル公開設定（公開または未公開）に指定はありません。",
        "参加者は複数の大会用口座で参加できますが、最も成績の良い口座のみがランキングに表示されます。",
        "公平性を期すため、大会用口座はSTD口座およびPRO口座のみとされます。PAMM口座やスワップフリー口座では参加できません。",
      ],
    },
    {
      t: "3.賞金について",
      c: [
        "1位入賞者は20万ドルの賞金または100万ドル分の入金済取引口座のいずれかを選ぶことができます。",
        "100万ドルの取引口座を選択した場合、当該口座における最大損失限度額は20万ドルとなります。また、当該口座から出金される場合は、20万ドルと実現損益金額の合計となります。 ",
        "その他の賞金は順位に応じて授与されます。 ",
      ],
    },
    {
      t: "4.禁止事項 ",
      c: [
        "口座間のアービトラージ取引や、複数の口座によるクロスポジション、ヘッジ取引などの不公正取引行為を行った場合は、参加資格が剥奪されます。",
        "サーバー故障や価格配信エラーなどのような不可抗力による注文は無効とします。",
      ],
    },
    {
      t: "5.本イベントの最終解釈権は当社に帰属します。",
      c: [],
    },
  ],
  参赛流程: "参加方法",
  注册开户: "新規登録",
  注册并开设新交易账号: "新規取引口座の開設",
  报名参赛: "参加登録",
  在用户中心报名参赛: "マイページから参加登録する",
  参赛成功: "参加登録完了",
  参赛成功查看实时赛况: "ランキングを確認可能",
  往届回顾: "過去の大会の様子",
  第一届交易大赛颁奖典礼: "第1回取引大会表彰式",
  第一届交易大赛高光时刻: "第1回取引大会のハイライト",
  第一届交易大赛巅峰对决: "第1回取引大会の最終決戦",
  查看更多: "もっと見る",
  常见问题: "よくある質問",
  qaList: [
    {
      t: "1. 「EBC口座」と「取引口座」について",
      c: [
        "EBC口座：EBCのマイページにログインするアカウントを指します。お客様は本人確認認証後、1つのEBC口座（UID）しか持つことができません。",
        "取引口座：MT4/MT5の取引口座を指します。各EBC口座の下に複数の取引口座を作成することができます。",
      ],
    },
    {
      t: "2. 大会への登録状況はどのように確認すればよいですか？",
      c: [
        "登録が完了すると、マイページの大会ページでお客様ご自身のランキングを確認することができます。",
      ],
    },
    {
      t: "3. 複数の取引口座で参加できますか？",
      c: [
        "各チャレンジコースに複数の取引口座で参加することはできますが、ランキングには最も成績の良い取引口座のみが表示されます。また、同一の取引口座は一つのコースのみ参加が可能です。",
      ],
    },
    {
      t: "4. 二つのコースから賞金を同時に獲得することはできますか？",
      c: [
        "できます。大会ルールに沿って参加した場合、二つのコースから賞金を獲得することができます。",
      ],
    },
    {
      t: "5. すでにMT4口座を持っていますが、大会に参加できますか?",
      c: [
        "取引口座は、2025年2月22日08:00:00（日本時間）以降に作成された新規取引口座で、取引履歴がないことが必要です。",
      ],
    },
    {
      t: "6. 3万ドルチャレンジコースに参加しましたが、あとからその取引口座に10,000ドル以上入金した場合、コースは変更されますか？",
      c: [
        "いいえ、コースは登録時に設定されるため、その後の残高変動がコースに影響することはありません。",
      ],
    },
    {
      t: "7. 大会ランキングはどのように確認できますか？",
      c: [
        "ランキングは1時間ごとに更新され（毎正時ではありません）、大会ページで確認できます。",
      ],
    },
    {
      t: "8. 大会中に入出金はできますか？",
      c: [
        "入出金は可能ですが、収益率の計算に影響を与えます。",
        "利益率（%）=（最終残高額 / 累積入金額）x 100",
      ],
    },
    {
      t: "9. 紹介報酬の受け取り方法は？",
      c: [
        "紹介報酬は、被紹介者が有効な大会用口座で初回取引を行った30日後に紹介者の口座残高に反映されます。出金は申請承認後、ご指定の出金方法で出金いただけます。",
      ],
    },
    {
      t: "10. 被紹介者は、大会期間中に紹介者を変更することができますか？",
      c: [
        "公平性を確保するため、大会期間中の変更はできません。大会終了後、お客様が紹介者以外への紐づけを希望される場合は、カスタマーサポートまでお問い合わせください。",
      ],
    },
  ],
};

